import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { BenefitWithPlanInfo } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  BusinessInfo,
  CartModal,
  CartModalStatus,
  Dialog,
  ServicePaymentDetails,
  SettingsSubTab,
  SettingsTab,
  TFunction,
} from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServiceType } from '@wix/bookings-uou-types';
import { BookingsDataCapsule } from '@wix/bookings-data-capsule';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import {
  getDefaultPaymentOptionId,
  getFirstAvailablePaymentOptionId,
} from '../payment/payment';
import { FormView, Submission } from '@wix/forms-ui/types';
import {
  getFieldsValuesInStorage,
  setIsEcomInStorage,
} from '../storageFunctions';
import { EmptyStateErrorType, FormErrors } from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { CouponInfo } from '../../types/coupons';
import { DynamicPriceInfo } from '../../types/dynamicPrice';
import {
  BookingRequestKeyMappings,
  getFieldFromSchema,
} from '../mappers/form-submission.mapper';
import { mapServicePaymentDto } from '@wix/bookings-uou-mappers';
import { FormStatus } from '../../types/form-state';
import {
  SelectedPaymentOption,
  ContactDetails,
} from '@wix/ambassador-bookings-gateway/types';

import { getCurrentTimezone } from '../timezone/timezone';
import { ListEligibleMembershipsResponse } from '@wix/ambassador-memberships-spi-host/http';
import { renderSEOTags } from '../seo/seo';
import { initiateDynamicPriceState } from './initiateDynamicPriceState';
import { ExperimentsConsts } from '../../consts/experiments';
import { getPageAPIData } from '../../api/PageAPIAdapter/pageApiAdapter';
import { isDayfulFlow } from '../validations/dayful';

export type EditorContext = {
  isDummy: boolean;
  selectedSettingsTabId?: SettingsTab;
  selectedSettingsSubTabId?: SettingsSubTab;
};

export type FormState = {
  service: Service;
  benefitsWithPlanInfo?: BenefitWithPlanInfo[];
  isPricingPlanInstalled: boolean;
  isMemberAreaInstalled: boolean;
  isCart?: boolean;
  shouldShowCollapseForm?: boolean;
  collapseFormValues?: ContactDetails;
  couponInfo: CouponInfo;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability: SlotAvailability;
  pricingPlanDetails?: PaidPlans;
  memberships?: ListEligibleMembershipsResponse;
  memberDetails?: Member;
  errors: FormErrors[];
  selectedPaymentOptionId: string;
  editorContext: EditorContext;
  status: FormStatus;
  overrideDefaultFieldsValues?: boolean;
  dialog?: Dialog;
  paymentDetails: ServicePaymentDetails;
  formInputs: FormInputs;
  selectedPaymentType: SelectedPaymentOption;
  isBookingsOnEcom: boolean;
  cartModal?: CartModal;
  dynamicPriceInfo?: DynamicPriceInfo;
  isDayful?: boolean;
};

export type FormInputs = {
  numberOfParticipants: number;
  email?: string;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  bookingsDataCapsule,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  bookingsDataCapsule: BookingsDataCapsule;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  try {
    const pageAPIData = await getPageAPIData({ flowAPI: flowApi });

    const isImproveFormPerformanceEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.ImproveFormPerformance,
    );
    let isBookingsOnEcom = false;
    if (isImproveFormPerformanceEnabled) {
      const isEcomFromSessionStorage = !!pageAPIData?.isEcom;
      isBookingsOnEcom =
        !!isEcomFromSessionStorage ||
        (await formApi.isBookingsOnEcom({
          onError: (error) => {
            throw error;
          },
        }));
      setIsEcomInStorage(wixSdkAdapter, isBookingsOnEcom.toString());
    } else {
      isBookingsOnEcom = await formApi.isBookingsOnEcom({
        onError: (error) => {
          throw error;
        },
      });
    }

    if (
      wixSdkAdapter.isEditorMode() ||
      (wixSdkAdapter.isPreviewMode() && !pageAPIData?.serviceId)
    ) {
      const [
        catalogData,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
      ] = await Promise.all([
        formApi.getCatalogData(),
        wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
        wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
      ]);

      if (!catalogData) {
        throw EmptyStateErrorType.INVALID_CATALOG_DATA;
      }

      return createDummyState({
        flowApi,
        businessInfo: catalogData.businessInfo,
        isMemberAreaInstalled,
        isPricingPlanInstalled,
        isBookingsOnEcom,
        isCart: await isCartEnabled({
          experiments: flowApi.experiments,
          wixSdkAdapter,
          businessInfo: catalogData!.businessInfo,
        }),
      });
    }

    if (!pageAPIData) {
      throw EmptyStateErrorType.INVALID_PAGE_API_DATA;
    }

    const {
      areCouponsAvailable,
      catalogData,
      benefitsWithPlanInfo,
      numberOfSessions,
      memberDetails,
      slotAvailability,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled,
      isMemberAreaInstalled,
      errors,
      bookingsLineItemOptions,
      serviceOptionsAndVariants,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      serviceId: pageAPIData.serviceId,
      slotAvailability: pageAPIData.slotAvailability,
      timezone: pageAPIData.timezone,
      isBookingsOnEcom,
      experiments: flowApi.experiments,
    });

    await renderSEOTags({ catalogData, wixSdkAdapter, t });

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError;
    }

    const preFilledValues: Maybe<Submission> = await getFieldsValuesInStorage(
      wixSdkAdapter,
      bookingsDataCapsule,
      flowApi.experiments,
      BookingsQueryParams.FILLED_FIELDS,
    );

    const isFixFormUoUCheckboxLinkEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.FixFormUoUCheckboxLink,
    );
    const isAlwaysShowComplexPhoneFieldEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.AlwaysShowComplexPhoneField,
    );

    const isCart = await isCartEnabled({
      experiments: flowApi.experiments,
      wixSdkAdapter,
      businessInfo: catalogData!.businessInfo,
    });

    const isDayfulMovePremiumBlockModalEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.DayfulMovePremiumBlockModal,
    );

    const {
      controllerConfig: { wixCodeApi },
      environment: { isPreview },
    } = flowApi;

    const isDayful = isDayfulMovePremiumBlockModalEnabled
      ? await isDayfulFlow(wixCodeApi, isPreview)
      : false;

    const shouldShowCollapseForm = (bookingsLineItemOptions?.length! || 0) > 0;

    const shouldShowContactFields = !shouldShowCollapseForm;

    const isCancellationPolicyChangeAPIEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.CancellationPolicyChangeAPI,
    );

    const service = mapCatalogServiceToService({
      catalogData: catalogData!,
      slotAvailability: slotAvailability!,
      pricingPlanDetails,
      memberships,
      preFilledValues,
      t,
      numberOfSessions: numberOfSessions!,
      serviceId: pageAPIData.serviceId,
      isDynamicPriceEnabled: !!serviceOptionsAndVariants,
      isFixFormUoUCheckboxLinkEnabled,
      isAlwaysShowComplexPhoneFieldEnabled,
      bookingsLineItemOptions,
      shouldShowContactFields,
      isCart,
      isCancellationPolicyChangeAPIEnabled,
    });

    const isCustomOptionsEnabled = flowApi.experiments.enabled(
      ExperimentsConsts.DynamicPricingCustomUoU,
    );

    const dynamicPriceInfo = await initiateDynamicPriceState({
      service,
      dynamicPricePreSelection: pageAPIData.dynamicPricePreSelection,
      formApi,
      isCustomOptionsEnabled,
      wixSdkAdapter,
      dateRegionalSettingsLocale: catalogData?.businessInfo
        .dateRegionalSettingsLocale!,
      serviceOptionsAndVariants,
    });

    const isDynamicPricingCustomOptions = !!dynamicPriceInfo?.customOptions;

    const defaultPaymentOptionId = getDefaultPaymentOptionId({
      settings,
      servicePayment: service.payment,
      pricingPlanDetails,
      memberships,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isDynamicPricingCustomOptions,
    });

    const numberOfParticipants = getNumberOfParticipantsValue(
      service.formSchema,
    );
    const email = getEmailValue(service.formSchema);
    const paymentDetails = mapServicePaymentDto(
      catalogData!.service,
    ) as ServicePaymentDetails;
    paymentDetails.totalPrice = paymentDetails.price;
    paymentDetails.payLater = paymentDetails.minCharge;

    const selectedPaymentOptionId = getFirstAvailablePaymentOptionId({
      service,
      memberships,
      pricingPlanDetails,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      businessInfo: catalogData!.businessInfo,
      selectedPaymentOptionId: defaultPaymentOptionId,
      isDynamicPreferenceType: dynamicPriceInfo?.isDynamicPreferenceType!,
      t,
      settings,
      numberOfParticipants,
    })!;

    const selectedPaymentType = getDefaultPaymentType(
      settings,
      service?.paymentTypes,
    );

    const contactDetailsFromFirstBookingOnCart =
      (bookingsLineItemOptions?.length || 0) >= 1
        ? (bookingsLineItemOptions?.[0]?.contactDetails as ContactDetails)
        : undefined;

    const collapseFormValues = shouldShowCollapseForm
      ? contactDetailsFromFirstBookingOnCart
      : undefined;

    return {
      activeFeatures: catalogData!.activeFeatures, // Todo: check if can remove it
      benefitsWithPlanInfo,
      service,
      dynamicPriceInfo,
      businessInfo: catalogData!.businessInfo,
      slotAvailability: slotAvailability!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      isMemberAreaInstalled: isMemberAreaInstalled!,
      ...(isCart ? { isCart, shouldShowCollapseForm, collapseFormValues } : {}),
      pricingPlanDetails,
      memberships,
      memberDetails,
      errors,
      paymentDetails,
      selectedPaymentOptionId,
      couponInfo: {
        areCouponsAvailable: areCouponsAvailable!,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: FormStatus.INITIALIZING,
      overrideDefaultFieldsValues: false,
      dialog: undefined,
      formInputs: {
        email,
        numberOfParticipants,
      },
      selectedPaymentType,
      isBookingsOnEcom,
      cartModal: {
        status: CartModalStatus.CLOSED,
        lineItems: [],
      },
      ...(isDayfulMovePremiumBlockModalEnabled ? { isDayful } : {}),
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}

export const getDefaultPaymentType = (
  settings: ControllerFlowAPI['settings'],
  paymentTypes: SelectedPaymentOption[],
): SelectedPaymentOption => {
  const defaultPaymentType = settings.get(settingsParams.defaultPaymentTime);
  if (paymentTypes.includes(defaultPaymentType)) {
    return defaultPaymentType;
  }

  if (paymentTypes.length === 1) {
    return paymentTypes[0];
  }

  return paymentTypes.filter(
    (paymentType) => paymentType !== SelectedPaymentOption.MEMBERSHIP,
  )[0];
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  serviceId,
  slotAvailability,
  timezone,
  isBookingsOnEcom,
  experiments,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  serviceId?: string;
  slotAvailability?: SlotAvailability;
  timezone: string;
  isBookingsOnEcom: boolean;
  experiments: Experiments;
}) => {
  let errors: FormErrors[] = [];

  if (!serviceId) {
    errors = [...errors, EmptyStateErrorType.INVALID_SERVICE_ID];
    return {
      errors,
    };
  }

  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;

  const [isPricingPlanInstalled, isMemberAreaInstalled] = await Promise.all([
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
  ]);
  const isLoggedInUser = user.loggedIn;
  const ImproveFormPerformance = experiments.enabled(
    ExperimentsConsts.ImproveFormPerformance,
  );
  const shouldGetMemberDetails =
    !ImproveFormPerformance && isMemberAreaInstalled && isLoggedInUser;
  const shouldGetPricingPlanDetails =
    isPricingPlanInstalled && isLoggedInUser && startTime;

  const shouldGetSerciceOptionsAndVariants = experiments.enabled(
    ExperimentsConsts.DynamicPricingUoU,
  );

  const [
    areCouponsAvailable,
    catalogData,
    benefitsWithPlanInfo,
    memberDetails,
    pricingPlanDetails,
    memberships,
    serviceOptionsAndVariants,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService(),
    formApi.getCatalogData({
      serviceId,
      resourceId,
      onError: () => {
        errors = [...errors, EmptyStateErrorType.INVALID_CATALOG_DATA];
      },
    }),
    formApi.getPricingPlansBenefitList(),
    shouldGetMemberDetails
      ? formApi
          .getMemberDetails({
            id: user.id,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetPricingPlanDetails && !isBookingsOnEcom
      ? formApi
          .getPricingPlanDetails({
            serviceId,
            startTime,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetPricingPlanDetails && isBookingsOnEcom && !wixSdkAdapter.isOwner()
      ? formApi
          .listMemberships({
            serviceId,
            startTime,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    shouldGetSerciceOptionsAndVariants && isBookingsOnEcom
      ? formApi
          .getOptionsAndVariantsData({
            serviceId,
            onError: (error) => (errors = [...errors, error]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
  ]);

  if (!catalogData) {
    return {
      errors,
    };
  }

  const isCartSpecEnabled = experiments.enabled(ExperimentsConsts.Cart);

  const shouldGetLineItemOptions = isCartSpecEnabled
    ? await wixSdkAdapter.isCartEnabled(catalogData.businessInfo)
    : false;

  const bookingsLineItemOptions = shouldGetLineItemOptions
    ? await formApi.getLineItemOptionsFromCart()
    : undefined;

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const type = getServiceType(activeSchedule);
  const isCourse = type === ServiceType.COURSE;
  let numberOfSessions = 1;

  if (isCourse) {
    if (!firstSessionStart) {
      errors = [...errors, EmptyStateErrorType.COURSE_WITHOUT_SESSIONS];
      return {
        errors,
      };
    }
    const [
      listSlots,
      courseAvailability,
      scheduleAvailability,
    ] = await Promise.all([
      lastSessionEnd
        ? formApi.getSlots({
            firstSessionStart: firstSessionStart!,
            lastSessionEnd,
            scheduleId,
            onError: (error) => (errors = [...errors, error]),
          })
        : {},
      !isBookingsOnEcom ? formApi.getAvailability({ scheduleId }) : undefined,
      isBookingsOnEcom
        ? formApi.getScheduleAvailability({ scheduleId })
        : undefined,
    ]);

    if (courseAvailability || scheduleAvailability) {
      const openSpots = isBookingsOnEcom
        ? scheduleAvailability?.openSpots
        : Number(courseAvailability!.capacity) -
          Number(courseAvailability!.totalNumberOfParticipants);
      const resolvedTimezone = getCurrentTimezone({
        pageApiTimezone: timezone,
        businessInfo: catalogData.businessInfo,
      });
      slotAvailability = {
        openSpots,
        slot: {
          startDate: firstSessionStart,
          endDate: lastSessionEnd,
          timezone: resolvedTimezone,
        },
      };
    }

    numberOfSessions = (listSlots as any)?.slots?.length || 1;
  } else {
    if (!slotAvailability?.slot) {
      errors = [...errors, EmptyStateErrorType.INVALID_SLOT_AVAILABILITY];
      return {
        errors,
      };
    }
  }

  return {
    catalogData,
    benefitsWithPlanInfo,
    numberOfSessions,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    memberships,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    errors,
    areCouponsAvailable,
    bookingsLineItemOptions,
    serviceOptionsAndVariants,
  };
};

const getNumberOfParticipantsValue = (formSchema: FormView): number => {
  const numberOfParticipants = getFieldFromSchema(
    formSchema,
    BookingRequestKeyMappings.NO_OF_PARTICIPANTS,
  )?.renderInfo?.displayProperties?.defaultValue;
  return (numberOfParticipants && Number(numberOfParticipants)) || 1;
};

const getEmailValue = (formSchema: FormView): string => {
  return getFieldFromSchema(formSchema, BookingRequestKeyMappings.EMAIL)
    ?.renderInfo?.displayProperties?.defaultValue;
};
async function isCartEnabled({
  experiments,
  wixSdkAdapter,
  businessInfo,
}: {
  experiments: Experiments;
  wixSdkAdapter: WixOOISDKAdapter;
  businessInfo: BusinessInfo;
}) {
  const isCartSpecEnabled = experiments.enabled(ExperimentsConsts.Cart);

  return (
    (isCartSpecEnabled && (await wixSdkAdapter.isCartEnabled(businessInfo))) ||
    false
  );
}
